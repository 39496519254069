import React from "react"
import styled from "styled-components"
import Inner from "../../layout/pageInner/Inner"
import BackgroungImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import SvgIcon from "../../atoms/icons/svg-icon"
import ArrowRight from "../../atoms/icons/files/arrow-right.jsx"
import Phone from "../../atoms/icons/files/phone.jsx"
import Mobile from "../../atoms/icons/files/mobile.jsx"
import Envelope from "../../atoms/icons/files/envelope.jsx"
import LocationPin from "../../atoms/icons/files/location-pin.jsx"
import Calendar from "../../atoms/icons/files/calendar.jsx"
import { formatEmailToLink, formatPhoneToLink, path } from "../../../lib/util"
import { LinkWrapper } from "../../../utils/linkWrapper"

export default function FeaturedStore({ store }) {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(PLACEHOLDER)

  const betterFluid = path(
    [
      "branch_details",
      "image",
      "imageFile",
      "localFile",
      "childImageSharp",
      "fluid",
    ],
    store
  )

  const phone1 =
    store.branch_details &&
    store.branch_details.telephoneNumbers &&
    store.branch_details.telephoneNumbers.phone1
  const phone2 =
    store.branch_details &&
    store.branch_details.telephoneNumbers &&
    store.branch_details.telephoneNumbers.phone2
  const email = store.branch_details && store.branch_details.email
  const address = store.branch_details && store.branch_details.address
  const operatingHours =
    store.branch_details && store.branch_details.operatingHours

  return (
    <Wrap>
      <Inner>
        <h5>Your Closest Store</h5>
        <CardWrap>
          <Content>
            <LinkWrapper to={`/contact-us/${store.slug}`}>
              <Title>
                {store.title} <SvgIcon SvgComponent={ArrowRight} size="md" />
              </Title>
            </LinkWrapper>
            {phone1 && (
              <IconRow>
                <SvgIcon
                  SvgComponent={Phone}
                  size="sm"
                  hue="tusk"
                  shade="080"
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatPhoneToLink(phone1),
                  }}
                />
              </IconRow>
            )}
            {phone2 && (
              <IconRow>
                <SvgIcon
                  SvgComponent={Mobile}
                  size="sm"
                  hue="tusk"
                  shade="080"
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatPhoneToLink(phone2),
                  }}
                />
              </IconRow>
            )}
            {email && (
              <IconRow>
                <SvgIcon
                  SvgComponent={Envelope}
                  size="sm"
                  hue="tusk"
                  shade="080"
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatEmailToLink(email),
                  }}
                />
              </IconRow>
            )}
            {address && (
              <IconRow>
                <SvgIcon
                  SvgComponent={LocationPin}
                  size="sm"
                  hue="tusk"
                  shade="080"
                />
                <div>{address}</div>
              </IconRow>
            )}
            {operatingHours && (
              <IconRow>
                <SvgIcon
                  SvgComponent={Calendar}
                  size="sm"
                  hue="tusk"
                  shade="080"
                />
                <div
                  style={{ whiteSpace: "pre" }}
                  dangerouslySetInnerHTML={{
                    __html: operatingHours,
                  }}
                />
              </IconRow>
            )}
          </Content>
          <StyledBackground fluid={betterFluid || fluid}>
            <LinkWrapper to={`/contact-us/${store.slug}`}>
              <Overlay />
            </LinkWrapper>
          </StyledBackground>
        </CardWrap>
      </Inner>
    </Wrap>
  )
}

const Content = styled.div``

const IconRow = styled.div`
  padding: 15px;
  display: grid;
  grid-template-columns: 40px auto;
  div {
    span.subtle {
      color: ${({ theme }) => theme.colors.tusk["080"]};
    }
  }
`

const Title = styled.div`
  background: #f6f6f6;
  color: #223247;
  font-size: 31px;
  font-weight: 300;
  display: flex;
  align-items: center;
  padding: 10px;
`

const CardWrap = styled.div`
  background: white;
  display: grid;
  grid-template-columns: 2fr 3fr;
  box-shadow: 0 13px 27px 0 rgba(4, 9, 25, 0.44);
  border-radius: 2px;
  overflow: hidden;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr;
  }
`

const StyledBackground = styled(BackgroungImage)`
  margin: 10px;
`
const Overlay = styled.div`
  background: linear-gradient(
    180deg,
    rgba(30, 36, 42, 0) 0%,
    rgba(16, 23, 32, 0.1) 100%
  );
  height: 100%;
`

const Wrap = styled.div`
  background: #223247;
  padding: 60px 0;

  h5 {
    color: #ffffff;
    font-family: Roboto;
    font-size: 20px;
    padding-bottom: 20px;
  }
`

const PLACEHOLDER = graphql`
  {
    file(relativePath: { eq: "team-image-placeholder.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
