import React, { useState, useLayoutEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Layout from "../../zzz/layout"
import SEO from "../../components/SEO/seo"
import Heading from "../../zzz/organisms/heading"
import Inner from "../../zzz/layout/pageInner/Inner"
import FeaturedAreaLink from "../../zzz/molecules/mainNavigation/contactMenu/FeaturedAreaLink"
import FeaturedStore from "../../zzz/organisms/featuredStore"
import { getFluid, calcCoordDistanceApprox } from "../../lib/util"

// ======================
// 	     COMPONENT
// ======================

const ContactUsPage = ({ location }) => {
  const data = useStaticQuery(QUERY)

  const branches = data.branchInfo.nodes
  const page = data.localWpGraphQlPages
  const seo = data.localWpGraphQlPages.seo

  const [closestBranchState, setClosestBranchState] = useState(false)

  useLayoutEffect(() => {
    // Use browser geolocation abilities
    try {
      // Try HTML5 geolocation.
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const browserPosition = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }
            console.log("User Location found: ", browserPosition)

            let closestBranch = false
            let closestBranchDistance = 99999
            if (browserPosition) {
              for (let branch of branches) {
                if (!branch.branch_details.branchDistributionOnly) {
                  if (!closestBranch) {
                    closestBranch = branch
                    closestBranchDistance = calcCoordDistanceApprox(
                      browserPosition,
                      closestBranch.branch_details
                    )
                  } else {
                    let distance = calcCoordDistanceApprox(
                      browserPosition,
                      branch.branch_details
                    )
                    if (distance < closestBranchDistance) {
                      closestBranchDistance = distance
                      closestBranch = branch
                    }
                  }
                }
              }
              if (closestBranch) {
                setClosestBranchState(closestBranch)
              }
            }
          },
          () => {
            console.log("User denied location")
          }
        )
      }
    } catch {}
  })

  // Reorder so that head office is last
  const orderedBranches = branches.sort((a, b) =>
    a.slug === "head-office" ? 1 : -1
  )
  return (
    <Layout location={location}>
      <SEO title="Contact Us" location={location} post_id={446} seo={seo} />
      <Heading
        location={location}
        subTitle={"Below is a list of all our branches across South Africa."}
        fluid={getFluid(page)}
      />
      {closestBranchState && <FeaturedStore store={closestBranchState} />}
      <BranchInformationContainer>
        <Inner>
          <TitleBox>
            <h2>All Our Stores</h2>
            <p>Below is a list of all our branches across South Africa.</p>
          </TitleBox>
          <Grid>
            {orderedBranches.map((node) => (
              <FeaturedAreaLink
                imgBorder
                key={node.id}
                raised
                branch={node}
                title={node.title}
                phone_1={node.branch_details.telephoneNumbers.phone1}
                phone_2={node.branch_details.telephoneNumbers.phone2}
                address={
                  !node.branch_details.branchDistributionOnly &&
                  node.branch_details.address
                }
                slug={node.slug}
                hours={node.branch_details.operatingHours}
              />
            ))}
          </Grid>
        </Inner>
      </BranchInformationContainer>
    </Layout>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  h2,
  p {
    text-align: center;
    color: #223247;
    margin: 10px 0;
  }
  h2 {
    border-bottom: 3px solid red;
  }
`

const BranchInformationContainer = styled.div`
  background: #f6f6f6;
  min-height: 100px;
  box-sizing: border-box;
  padding: 60px 0;
  .grid-container {
    display: grid;
    justify-items: center;
    align-items: baseline;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    max-width: 100%;
    a:link {
      color: white;
      text-decoration: none;
    }
    a:visited {
      color: white;
      text-decoration: none;
    }
    button {
      background: red;
      font-size: 14px;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    .grid-container {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .grid-container {
      grid-template-columns: 1fr;
      a {
        width: 100%;
      }
    }
  }
`

// ======================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ======================

const QUERY = graphql`
  {
    localWpGraphQlPages(pageId: { eq: 446 }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      slug
      featuredImage {
        sourceUrl
        mediaItemId
        imageFile {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    branchInfo: allLocalWpGraphQlBranches {
      nodes {
        id
        title
        content
        slug
        branch_details {
          address
          addressLink
          branchName
          email
          operatingHours
          latitude
          longitude
          # branchDistributionOnly
          image {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          telephoneNumbers {
            phone1
            phone2
            phone3
          }
        }
      }
    }
  }
`

export default ContactUsPage
